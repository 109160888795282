//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import { i18n } from '@/i18n';

const { fields } = UserModel;

export default {
  name: 'app-email-unverified-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
      },
      model: {},
    };
  },
  computed: {
    ...mapGetters({
      email: 'auth/currentUserEmail',
      loading: 'auth/loadingEmailConfirmation',
      loadingVerify: 'auth/loadingVerify',
      loadingEmailConfirmation: 'auth/loadingEmailConfirmation', 
    }),

    fields() {
      return fields;
    },
  },
  methods: {
    ...mapActions({
      doSendEmailConfirmation: 'auth/doSendEmailConfirmation',
      doSignout: 'auth/doSignout',
      doEmailVerified: 'auth/doEmailVerified',
    }),
    async doSubmit() {
      await this.doSendEmailConfirmation(this.model.email);
    },
    i18n(code) {
      return i18n(code);
    },
  },
};
